<template>
  <header class="site-header">
    <router-link to="/">
      <img alt="Ahlatcı" src="../assets/logo.svg" class="logo">
    </router-link>
    <!--<ul class="language-switcher">
      <li>
        <a href="javascript:;">TR</a>
      </li>
      <li>
        <a href="javascript:;">EN</a>
      </li>
    </ul>-->
  </header>
</template>

<script>
export default {
  name: 'Header',
};
</script>

<style scoped>

</style>
