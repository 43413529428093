import Vue from 'vue';
import { Row, Column, Hidden } from 'vue-grid-responsive';
import VueI18n from 'vue-i18n';
import AOS from 'aos';
import axios from 'axios';
import VueAxios from 'vue-axios';
import moment from 'moment';
import VueMoment from 'vue-moment';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import 'aos/dist/aos.css';

// Translations
import TURKISH from './translations/turkish';
import ENGLISH from './translations/english';

Vue.config.productionTip = false;

Vue.component('row', Row);
Vue.component('column', Column);
Vue.component('hidden', Hidden);

Vue.use(VueI18n);
Vue.use(VueAxios, axios);
Vue.use(VueMoment, { moment });

const translations = {
  tr: TURKISH,
  en: ENGLISH,
};

const i18n = new VueI18n({
  locale: 'tr',
  messages: translations,
});

new Vue({
  created() {
    AOS.init();
  },
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
