import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: 'Ahlatcı Döviz',
    },
  },
  {
    path: '/kurumsal',
    name: 'Corporate',
    component: () => import(/* webpackChunkName: "about" */ '../views/Corporate.vue'),
    meta: {
      title: 'Kurumsal - Ahlatcı Döviz',
    },
  },
  {
    path: '/sorumluluk-ve-uyum',
    name: 'Responsibilities',
    component: () => import(/* webpackChunkName: "about" */ '../views/Responsibilities.vue'),
  },
  {
    path: '/iletisim',
    name: 'Contact',
    component: () => import(/* webpackChunkName: "about" */ '../views/Contact.vue'),
  },
  {
    path: '/kisiel-verilerin-korunmasi',
    name: 'GDPR',
    component: () => import(/* webpackChunkName: "about" */ '../views/GDPR.vue'),
  },
  {
    path: '/bilgi-toplumu-hizmetleri',
    name: 'Information',
    component: () => import(/* webpackChunkName: "about" */ '../views/Information.vue'),
  },
  {
    path: '/kalite-belgeleri',
    name: 'QualityCertificates',
    component: () => import(/* webpackChunkName: "about" */ '../views/QualityCertificates.vue'),
  },
  {
    path: '/politikalarimiz',
    name: 'Principles',
    component: () => import(/* webpackChunkName: "about" */ '../views/Principles.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: -50 };
  },
});

export default router;
