<template>
  <footer class="site-footer">
    <div class="container-wrapper">
      <div class="divider"></div>
      <div class="copyright">{{ $t('footer.copyright') }}</div>
      <ul class="footer-menu">
        <li v-for="(item, index) in $t('footer.menu')" v-bind:key="index">
          <router-link :to="item.link">
            {{ item.text }}
          </router-link>
        </li>
      </ul>
      <div class="bottom-infos">
        <div>Eğridere Cad. No:17/3 Merkez/Çorum - 19100 Turkiye</div>
        <div>{{ $t('footer.phone') }}: 444 10 02</div>
        <div>Faks: 0 (364) 224 11 57</div>
        <div>{{ $t('footer.email') }}: kurumsal@ahlatci.com.tr</div>
      </div>
      <a class="footerLogo" href="https://borsaistanbul.com" title="Borsa İstanbul" target="_blank" alt="Borsa İstanbul"></a>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'Footer',
};
</script>

<style scoped>

</style>
