export default {
  home: {
    currencies: 'Döviz kurları',
    lastUpdateTime: 'Son Güncelleme Zamanı',
    gold: 'Altın',
    currency: 'Döviz Kurları',
    about: 'Hakkında',
  },
  about: {
    description: '<span>Ahlatcı Döviz</span> demek; bugün Türkiye’nin, hatta dünyanın en  güçlü markalarından biri demektir.',
    // eslint-disable-next-line max-len
    content: '<p>Borsa İstanbul Kıymetli Madenler üyesi olarak yurtiçi ve yurtdışı borsalarında 1998 yılından beri işlem yapan Ahlatcı Döviz Anadolu’da bir ilke imza atarak 2008 yılında da T.C Darphane Yetkili Özel Ayar Evi yetkisini almıştır. Yetkisi sayesinde Ankara ve doğusundaki tüm illerdeki gümrük müdürlüklerine hizmet vermektedir.</p><p>Türkiye’nin üçüncü altın rafinerisini 2012 yılında kuran Ahlatcı Döviz faaliyetlerini 15 bin metrekare kapalı alan içinde sürdürmektedir. Bugün hala hala kurulduğu Çorum’da yer almakta olup, bölgenin tüm döviz ihtiyacını karşılamaktadır.</p><p>Yatırımlarını günden güne büyüten Ahlatcı Döviz hem istikrarlı büyümesiyle hem de duruşuyla müşterilerine ve ortaklarına önce güven verir.</p>',
    // eslint-disable-next-line max-len
    contentRight: "<p><span class='featured-letter'>B</span> Bu başarı hikayesi 1984’te Çorum’un Eğridere Çarşısı’nda küçük bir kuyumcu dükkanı olarak başlar. Ahlatcı ailesi seneler boyunca yılmadan çalışarak başarılarına başarı katar, önlerine çıkan fırsatları her zaman doğru değerlendirir. İleri görüşlülükleriyle önce toptan bilezik ve alyans satışına geçen aile 1992 yılında dövüz bürosu yetkisini alır ve işlerine ivme kazandırır. Vaktinde attıkları doğru adımlarla Ahlatcı Döviz hâlihazırda T.C Merkez Bankası Para Efektif Piyasaları'nın Türkiye'deki 8 üyesinden biridir.</p>",
    tagline: '<span>Ahlatcı Döviz</span> demek başarı, güven, istikrar demektir.',
    qualityCertificates: 'Kalite Belgelerimiz',
  },
  president: {
    presidentMessage: 'Başkan mesajı',
    // eslint-disable-next-line max-len
    contentRight: "<p><span class='featured-letter'>A</span> 1984 yılında mütevazı bir kuyumcu esnafı olarak başladığım bu yolculukta, sevgili ailem ve çalışma arkadaşlarımın yadsınamaz özverileri ve gayretleriyle, çok çalışarak, yıldan yıla düzenli, tempolu biçimde büyüyerek bugünlere geldik.</p><p>Ahlatcı Holding olarak, 2017 yılı itibariyle, Çorum İlimizin en büyük şirketler grubu olmanın haklı gururunu, bunun yanı sıra da sorumluluğunu taşıyoruz. Grubumuz, 6 ana sektörde 34 şirketle faaliyet gösteriyor.</p> <p>Üç bin beş yüz çalışanımızla, çözüm ortağımız şirketlerle ve esnafımızla, Çorum’da direkt ve dolaylı olarak 10 bin hanenin geçimine katkı sağlıyoruz. Yatırımlarımıza ara vermeden ve hızla devam ediyoruz. Amacımız Çorum’dan yola çıkarak Türkiye ekonomisine elimizden geldiğince katkıda bulunmaktadır.</p> <p>Artık sıradan bir şirketler grubu olmadığımızın farkındayız. Sosyal sorumluluklarımızın bilincindeyiz ve bu doğrultuda hareket etmeye özen gösteriyoruz. Grubumuzun ruhunu ifade eden güzel bir sözle bu satırları noktalamak istiyorum: “Bugün” kazananların, “yarın” ise kaybedenlerin sözüdür.</p>",
    tagline: '<span>“Bugün”</span> kazananların, <span>“yarın”</span> ise kaybedenlerin sözüdür.',
  },
  footer: {
    copyright: '© 2021. AHLATCI DÖVİZ',
    menu: [
      {
        text: 'Kurumsal',
        link: '/kurumsal',
      },
      {
        text: 'Sorumluluk ve Uyum',
        link: '/sorumluluk-ve-uyum',
      },
      {
        text: 'Kalite Belgelerimiz',
        link: '/kalite-belgeleri',
      },
      {
        text: 'İletişim',
        link: '/iletisim',
      },
      {
        text: 'Kişisel Verilerin Korunması',
        link: '/kisiel-verilerin-korunmasi',
      },
      {
        text: 'Politikalarımız',
        link: '/politikalarimiz',
      },
    ],
    phone: 'Telefon',
    email: 'E-Posta',
  },
};
