const currentYear = new Date().getFullYear();

export default {
  currentInsuranceCompany: 'Current health insurance',
  postCode: 'Postcode',
  region: 'Place',
  ageGroup: 'Age group',
  compareNow: 'Request an offer now',
  calculatePremiums: 'Compare health insurance premiums for 2020',
  selectInsuranceModel: 'The insurance model:',
  startingFrom: 'from',
  currentFamilyDoctor: 'Aktueller Hausarzt',
  typeToFindDoctors: "Enter your doctor's name",
  selectDesiredDeductible: 'Franchise (deductible):',
  currentInsuranceCompanyPremium: 'Premium on your acutal health insurance (Standard)',
  savingPotential: 'Savings potential',
  savingPotentialDescription: '',
  year: 'Year',
  requestQuote: 'Request an offer now',
  requestAnOffer: 'Request an offer',
  requestDetailedOffer: 'Request a detailed offer now',
  requestDetailedOfferDescription: `You'll receive a non-binding, free, detailed offer with premiums for ${currentYear}`,
  whatIsImportant: 'What is important?',
  male: 'Mr',
  female: 'Ms',
  gender: 'Gender',
  firstName: 'First name',
  lastName: 'Last name',
  street: 'Street',
  number: 'Number',
  phone: 'Phone',
  email: 'Email',
  dateOfBirth: 'Date of birth',
  personsInHousehold: 'Persons in household',
  send: 'Send',
  franchise: 'Franchise',
  addPerson: 'Add person',
  removePerson: 'Remove person',
  next: 'Next',
  calculating: 'Calculating...',
  select: 'Select',
  goBack: 'Go back',
  thanks: 'Thank you!',
  yourPostcode: 'Your postcode',
  birthYear: 'Birth Year',
  accidentCoverage: 'Accident Coverage',
  model: 'Model',
  currentCashRegister: 'Current Insurance',
  currentInsuranceModel: 'Current Doctor Model',
  healthInsurance: 'Health Insurance',
  premiumPerMonth: 'Premium / Month',
  detailsFor: 'Details',
  total: 'Total',
  forAdditionalInsurance: 'Continue to the supplementary insurance',
  switchableForms: 'Request Offer',
  hospital: 'Hospital',
  supplementaryInsurance: 'Supplementary Insurance',
  back: 'Back',
  noOffersFoundWarning: "We couldn't find any offers for you.",
  pricesCanBeChanged: 'All data according to providers and subject to change.',
  selectAll: 'Select all',
  deselectAll: 'Deselect all',
  language: 'Language',
  importantTopics: 'Important topics',
  offerInformation: 'Offer information for Person',
  name: 'Name',
  doctorName: 'Doctor first name',
  doctorLastName: 'Doctor last name',
  newInsuranceCompany: 'New insurance company',
  newModel: 'New model',
  mainPremium: 'Main premium',
  needs: 'Needs',
  additionalPremium: 'Additional premium',
  contactInfos: 'Contact information',
  basicInsurance: 'Basic insurance',
  perMonth: 'Per month',
  selectedHealthInsurance: 'Selected health insurance',
  alternativeHealthInsurance: 'Alternative health insurances',
  continueWithAdditionalInsurance: 'Continue with additional insurance',
  continueWithoutAdditionalInsurance: 'Continue without additional insurance',
};
