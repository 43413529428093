<template>
  <div id="app">
    <Header/>
    <router-view/>
    <Footer/>
  </div>
</template>

<script>
import Header from './components/Header.vue';
import Footer from './components/Footer.vue';

export default {
  components: {
    Header,
    Footer,
  },
};
</script>

<style lang="scss">
@font-face {
  font-family: 'Mont';
  src: url('./fonts/Mont-Regular.eot');
  src: url('./fonts/Mont-Regular.eot?#iefix') format('embedded-opentype'),
  url('./fonts/Mont-Regular.woff2') format('woff2'),
  url('./fonts/Mont-Regular.woff') format('woff'),
  url('./fonts/Mont-Regular.ttf') format('truetype'),
  url('./fonts/Mont-Regular.svg#Mont-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Mont';
  src: url('./fonts/Mont-Light.eot');
  src: url('./fonts/Mont-Light.eot?#iefix') format('embedded-opentype'),
  url('./fonts/Mont-Light.woff2') format('woff2'),
  url('./fonts/Mont-Light.woff') format('woff'),
  url('./fonts/Mont-Light.ttf') format('truetype'),
  url('./fonts/Mont-Light.svg#Mont-Light') format('svg');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Mont';
  src: url('./fonts/Mont-Black.eot');
  src: url('./fonts/Mont-Black.eot?#iefix') format('embedded-opentype'),
  url('./fonts/Mont-Black.woff2') format('woff2'),
  url('./fonts/Mont-Black.woff') format('woff'),
  url('./fonts/Mont-Black.ttf') format('truetype'),
  url('./fonts/Mont-Black.svg#Mont-Black') format('svg');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Mont';
  src: url('./fonts/Mont-SemiBold.eot');
  src: url('./fonts/Mont-SemiBold.eot?#iefix') format('embedded-opentype'),
  url('./fonts/Mont-SemiBold.woff2') format('woff2'),
  url('./fonts/Mont-SemiBold.woff') format('woff'),
  url('./fonts/Mont-SemiBold.ttf') format('truetype'),
  url('./fonts/Mont-SemiBold.svg#Mont-SemiBold') format('svg');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Mont';
  src: url('./fonts/Mont-Bold.eot');
  src: url('./fonts/Mont-Bold.eot?#iefix') format('embedded-opentype'),
  url('./fonts/Mont-Bold.woff2') format('woff2'),
  url('./fonts/Mont-Bold.woff') format('woff'),
  url('./fonts/Mont-Bold.ttf') format('truetype'),
  url('./fonts/Mont-Bold.svg#Mont-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@import "styles/project";
</style>
