<template>
  <div class="home">
    <Hero/>
    <About/>
    <President/>
  </div>
</template>

<script>
import Hero from '@/components/Hero.vue';
import About from '@/components/About.vue';
import President from '@/components/President.vue';

export default {
  name: 'Home',
  data() {
    return {};
  },
  components: {
    Hero,
    About,
    President,
  },
};

</script>
