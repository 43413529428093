<template>
  <div class="president">
    <div class="container-wrapper">
      <h2 class="section-title"
          data-aos="fade-up"
          data-aos-delay="0">
        <span>{{ $t('president.presidentMessage') }}</span>
        Başkan mesajı
      </h2>
      <div class="divider"
           data-aos="fade-up"
           data-aos-delay="300"></div>
      <row :gutter="120"
           data-aos="fade-up"
           data-aos-delay="600">
        <column :xs="12" :md="6" :lg="6" class="py-0">
          <div class="president-image">
            <img src="../assets/president.jpeg">
          </div>
        </column>
        <column :xs="12" :md="6" :lg="6" class="py-0">
          <div class="content">
            <p>
              1984 yılında mütevazı bir kuyumcu esnafı olarak başladığım
              bu yolculukta, sevgili ailem ve çalışma arkadaşlarımın yadsınamaz
              özverileri ve gayretleriyle, çok çalışarak, yıldan yıla düzenli,
              tempolu biçimde büyüyerek bugünlere geldik.
            </p>
            <p>
              Ahlatcı Holding olarak, 2017 yılı itibariyle, Çorum İlimizin en
              büyük şirketler grubu
              olmanın haklı gururunu, bunun yanı sıra da sorumluluğunu taşıyoruz.
              Grubumuz, 6 ana sektörde 49 şirketle faaliyet gösteriyor.
            </p>
            <p>
              Üç bin çalışanımızla, çözüm ortağımız şirketlerle ve esnafımızla, Çorum’da
              direkt ve dolaylı olarak 10 bin hanenin geçimine katkı sağlıyoruz.
              Yatırımlarımıza ara vermeden ve hızla devam ediyoruz.
            </p>
            <p>
              Amacımız Çorum’dan yola çıkarak Türkiye ekonomisine elimizden geldiğince
              katkıda bulunmaktadır. Artık sıradan bir şirketler grubu
              olmadığımızın farkındayız. Sosyal sorumluluklarımızın bilincindeyiz
              ve bu doğrultuda hareket etmeye özen gösteriyoruz.
              Grubumuzun ruhunu ifade eden güzel bir sözle bu satırları
              noktalamak istiyorum:
            </p>
          </div>
          <div class="tagline featured-text" v-html="$t('president.tagline')"></div>
        </column>
      </row>
    </div>
  </div>
</template>

<script>
export default {
  name: 'President',
  data() {
    return {};
  },
  methods: {},
};
</script>
