<template>
  <div class="about">
    <div class="container-wrapper">
      <h2 class="section-title"
          data-aos="fade-up"
          data-aos-delay="0">
        <span>{{ $t('home.about') }}</span>
        Ahlatcı Döviz
      </h2>
      <div class="divider"
           data-aos="fade-up"
           data-aos-delay="300"></div>
      <row :gutter="120"
           data-aos="fade-up"
           data-aos-delay="600">
        <column :xs="12" :md="6" :lg="6" class="py-0">
          <div class="description featured-text" v-html="$t('about.description')"></div>
          <div class="content" v-html="$t('about.content')"></div>
          <router-link to="/kalite-belgeleri" class="button-gold">
            {{ $t('about.qualityCertificates') }}
            <img src="../assets/arrow-right.svg">
          </router-link>
        </column>
        <column :xs="12" :md="6" :lg="6" class="py-0">
          <div class="content" v-html="$t('about.contentRight')"></div>
          <div class="about-images">
            <img src="../assets/about-1.jpeg">
            <img src="../assets/about-2.jpeg" class="bottom">
          </div>
        </column>
      </row>
      <div class="tagline featured-text"
           data-aos="fade-up"
           data-aos-delay="900"
           v-html="$t('about.tagline')"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'About',
  data() {
    return {};
  },
  methods: {},
};
</script>
