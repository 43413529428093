<template>
  <div class="hero">
    <div class="container-wrapper">
      <h1 class="section-title" data-aos="fade-up">
        <span>Ahlatcı</span> {{ $t('home.currencies') }}
      </h1>
      <div class="divider"
           data-aos="fade-up"
           data-aos-delay="300"></div>
      <div id="lastUpdate" class="last-update"
           data-aos="fade-up"
           data-aos-delay="600">
      </div>
      <div class="tabs"
           data-aos="fade-up"
           data-aos-delay="900">
        <!--<div class="tabs-navigation">
          <a href="javascript:;"
             @click="setActiveTab(1)"
             :class="{active: activeTab === 1}">{{ $t('home.currency') }}</a>
        </div>-->
        <div class="tab-body">
          <div class="tab-content" v-show="activeTab === 1">
            <table id="currencyContainer" cellpadding="0" cellspacing="0"></table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: 'Hero',
  data() {
    return {
      activeTab: 1,
      currencies: [],
      showAll: false,
    };
  },
  beforeMount() {
    this.getCurrencies();
  },
  methods: {
    setActiveTab(tabIndex) {
      this.activeTab = tabIndex;
    },
    getCurrencies() {
      this.axios.get('/static/currencies.json').then((response) => {
        this.currencies = response.data;
      });
    },
    toggleCurrencies() {
      this.showAll = !this.showAll;
    },
  },
};
</script>
